import React, { useState } from "react";
import emailIllustration from "../../email-illustration.png";
import { leadApiURL } from "../../util/apiUrls";
import Button from "../button/button-container";
import "./emailcollect.scss";

type emailCollectProps = {
  emailCollected: boolean;
  setEmailCollected: React.Dispatch<React.SetStateAction<boolean>>;
};

const EmailCollect: React.FC<emailCollectProps> = ({
  emailCollected,
  setEmailCollected,
}) => {
  const [emailSubmitting, setEmailSubmitting] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");
  const jobID = window.location.pathname.split("/")[2];

  const handleChangeEmail = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const emailVal = event.target.value;
    setEmail(emailVal);
  };

  const handleEmailSubmit = async (): Promise<void> => {
    setEmailSubmitting(true);
    const response = await fetch(leadApiURL, {
      method: "POST",
      body: JSON.stringify({
        email,
        jobID,
      }),
    });
    if (response.ok) {
      setEmailCollected(true);
    } else {
      setEmailError("Please provide a valid email");
    }
    setEmailSubmitting(false);
  };

  // if email has not been collected

  if (!emailCollected) {
    return (
      <div className="email-collect container email-collet--not-collected">
        <div className="email-collect__right">
          <div className="email-collect__header">
            Want to see how your website compares with your competitors under
            different devices and network conditions?
          </div>
          <div className="email-collect__content">
            <form
              className="email-collect__form"
              onSubmit={(e): void => {
                e.preventDefault();
                handleEmailSubmit();
              }}
            >
              <input
                type="email"
                pattern="\b[\w\.-]+@((?!gmail|yahoo|hotmail|live|aol|outlook).)[\w\.-]+\.\w{2,4}\b"
                className="email-collect__content__input"
                placeholder="Enter your email"
                value={email}
                onChange={handleChangeEmail}
                title="Please use your work email rather than your personal one."
              />
              <Button
                className="btn btn--primary"
                type="submit"
                buttonText={
                  emailSubmitting ? (
                    <div className="loadingSpinner"></div>
                  ) : (
                    "Submit"
                  )
                }
              />
            </form>
          </div>
          <div className="email-collect__error">{emailError}</div>
        </div>
      </div>
    );
  }

  // if email has been collected
  return (
    <div className="email-collect container email-collet--not-collected">
      <div className="email-collect__left">
        <img src={emailIllustration} alt="" />
      </div>
      <div className="email-collect__right">
        <div className="email-collect__header">
          Thank you for submitting your email, we will be in touch!
        </div>
      </div>
    </div>
  );
};

export default EmailCollect;
