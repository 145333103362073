import cogoToast from "cogo-toast";
import debounce from "debounce-promise";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import illustration from "../../laptop-illustration.svg";
import { industryOptions } from "../../util/data";
import { getJobId, getUrlOptions } from "../../util/getUtil";
import Button from "../button/button-container";

const FrontHeroContainer: React.FC = () => {
  const selectRef = React.useRef<any>(null);
  const [url, setUrl] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [industry, setIndustry] = useState("");
  const [formError, setFormError] = useState("");
  const [resultPageLoading, setResultPageLoading] = useState(false);
  // const [selectedProtocol, setSelectedProtocol] = useState(protocolOptions[0]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleUrlSelect = (selected: any): void => {
    selectRef.current?.blur();
    if (selected) setUrl(selected.value);
    // TODO: When nothing is selected and still input URL is provided, set that as URL
  };

  const handleIndustrySelect = (selected: any): void => {
    if (selected) setIndustry(selected.value);
    // TODO: Check if industry is working
  };

  const debouncedLoadOptions = useRef(debounce(getUrlOptions, 500)).current;

  const handleFormSubmit = async (): Promise<void> => {
    if (!url) return setFormError("Please provide a valid url");
    setResultPageLoading(true);
    const jobId = await getJobId(url, industry, "all", "all");
    if (jobId) return history.push(`/result/${jobId}`);
    setResultPageLoading(false);
    cogoToast.error("Something went wrong, please try again");
  };

  return (
    <div className="front__hero container">
      <div className="front__illustration hidden__on__mobile">
        <img src={illustration} alt="" />
      </div>
      <div className="front__form">
        <div className="front__form__text">
          <div className="front__form__title">
            Measure the performance of your website against your competitors
          </div>
        </div>
        <div className="front__illustration hidden__on__desktop">
          <img src={illustration} alt="" />
        </div>
        <div className="front__form__url">
          <div className="front__form__error">{formError}</div>
          <div className="front__form__url__input">
            <div
              className="optionsLoader"
              style={{ visibility: loading ? "visible" : "hidden" }}
            ></div>
            <AsyncSelect
              ref={selectRef}
              loadOptions={(inputValue: string): Promise<object> => {
                setLoading(true);
                return debouncedLoadOptions(inputValue).then((res) => {
                  setLoading(false);
                  return res;
                });
              }}
              inputValue={inputValue}
              onInputChange={(value: string): void => {
                const trimmedVal = value.trim();
                if (trimmedVal !== "") setLoading(true);
                setInputValue(trimmedVal);
              }}
              onBlur={(): void => setLoading(false)}
              onFocus={(): void => {
                const val = url;
                setInputValue(val);
                selectRef.current?.select.onInputChange(val);
              }}
              noOptionsMessage={({
                inputValue,
              }: {
                inputValue: string;
              }): string =>
                `${
                  inputValue?.length > 0
                    ? "Not enough traffic for analysis"
                    : "Enter your website URL"
                }`
              }
              onChange={handleUrlSelect}
              placeholder="Enter your website URL"
              isMulti={false}
              classNamePrefix="front__form__url__input"
            />
          </div>
          <div className="front__form__industry__input">
            <Select
              name="industryOptions"
              options={industryOptions}
              onChange={handleIndustrySelect}
              classNamePrefix="front__form__industry__input"
              placeholder="Select your industry (optional)"
            />
          </div>
          <div className="front-hero-button-container">
            <Button
              className="btn btn--primary btn--w183"
              onClick={handleFormSubmit}
              buttonText={
                resultPageLoading ? (
                  <div className="loadingSpinner"></div>
                ) : (
                  "Analyze your website"
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrontHeroContainer;
