import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { getJobId } from "../../util/getUtil";
import Button from "../button/button-container";
import PlayButton from "../playbutton/playbutton-container";
import "./competitoranalysis.scss";

const connectionOptions = [
  {
    label: "all",
    value: "all",
  },
  {
    label: "3g",
    value: "3g",
  },
  {
    label: "4g",
    value: "4g",
  },
];

const deviceOptions = [
  {
    label: "all",
    value: "all",
  },
  {
    label: "desktop",
    value: "desktop",
  },
  {
    label: "phone",
    value: "phone",
  },
  {
    label: "tablet",
    value: "tablet",
  },
];

type CompetitorAnalysisProps = {
  onhandleClickChangeCompetitorsModal(event: any): void;
  competitorData: CompetitorDataType[];
  analysisCompleted: boolean;
  industry: string;
  emailEntered: boolean | null;
  deviceType: string;
  connectionType: string;
  onlyCollectEmail: boolean;
  origin: string | null;
  notEnoughDataUrls: any[];
};

const CompetitorAnalysis: React.FC<CompetitorAnalysisProps> = (props) => {
  const {
    competitorData,
    analysisCompleted,
    industry,
    emailEntered,
    onhandleClickChangeCompetitorsModal,
    deviceType,
    connectionType,
    origin,
    onlyCollectEmail,
    notEnoughDataUrls,
  } = props;

  const [currentData, setCurrentData] = useState<ProcessedCompetitorData[]>([]);
  const [timerStart, setTimerStart] = useState(0);
  const [timerTime, setTimerTime] = useState(0);
  const [playingNow, setPlayingNow] = useState(false);
  const [second, setSecond] = useState(0);
  const [previousSecond, setPreviousSecond] = useState(0);

  const [connection, setConnection] = useState({ label: "all", value: "all" });
  const [device, setDevice] = useState({ label: "all", value: "all" });
  const [updatingData, setUpdatingData] = useState(false);
  const [waitingForTrigger, setWaitingForTrigger] = useState(false);

  useEffect(() => {
    if (onlyCollectEmail === false && waitingForTrigger) {
      handleUpdateBtn();
      setWaitingForTrigger(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlyCollectEmail, waitingForTrigger]);

  useEffect(() => {
    setDevice({ label: deviceType, value: deviceType });
  }, [deviceType]);
  useEffect(() => {
    setConnection({ label: connectionType, value: connectionType });
  }, [connectionType]);

  const handleConnectionChange = (data: any): void => setConnection(data);

  const handleDeviceChange = (data: any): void => setDevice(data);

  const handleUpdateBtn = async (): Promise<void> => {
    if (updatingData) return;
    if (!props.emailEntered) {
      setWaitingForTrigger(true);
      return onhandleClickChangeCompetitorsModal(true);
    }
    setUpdatingData(true);
    const jobId = await getJobId(origin, "", device.value, connection.value);
    setUpdatingData(false);
    if (jobId) {
      window.location.href = `${window.location.origin}/result/${jobId}`;
    }
  };

  const play = (): void => {
    const { notEnoughDataUrls, competitorData, analysisCompleted } = props;
    if (
      !playingNow &&
      analysisCompleted &&
      competitorData.length &&
      !notEnoughDataUrls.includes(origin)
    ) {
      // starting to play
      const startingTime = Date.now();
      setCurrentData([]);
      setPlayingNow(true);
      setTimerTime(startingTime);
      setTimerStart(startingTime);
    }
  };

  useEffect(play, [competitorData, analysisCompleted]);

  useEffect(() => {
    if (!playingNow) return;
    // stop after 10 seconds
    if (timerTime - timerStart >= 10001) return setPlayingNow(false);
    window.setTimeout(() => {
      setTimerTime(timerTime + 100);
    }, 100);
    setSecond(Math.floor((timerTime - timerStart) / 1000) % 60);
  }, [timerTime, timerStart, playingNow]);

  useEffect(() => {
    // when second changes, update the current data
    if (second === 0) {
      const updatedCurrentData: ProcessedCompetitorData[] = [];
      for (const result of competitorData) {
        if (result?.url) {
          updatedCurrentData.push({
            // Removing https and www
            url: result["url"].replace(/^(?:https?:\/\/)?(?:www\.)?/i, ""),
            result: [],
            progress: 0,
            highlighted: false,
          });
        }
      }
      if (updatedCurrentData.length)
        updatedCurrentData[updatedCurrentData.length - 1].highlighted = true;
      return setCurrentData([...updatedCurrentData]);
    }
    setCurrentData((prevData) => {
      if (previousSecond === second) return prevData;
      const updatedData = [...prevData];
      setPreviousSecond(second);
      for (const index in competitorData) {
        if (updatedData[index]) {
          const currentProgress =
            competitorData?.[index]?.loadingExperience?.fcp?.[second] ?? 0;
          updatedData[index].result.push({
            value: currentProgress,
            type: second,
          });
          updatedData[index].progress += currentProgress * 100;
        }
      }
      return [...updatedData];
    });
  }, [second, competitorData, analysisCompleted, previousSecond]);

  const notEnoughData: boolean =
    notEnoughDataUrls.includes(origin) || currentData.length < 5;

  return analysisCompleted ? (
    <div className="competitor-analysis">
      <div className="competitor-analysis__header">
        <div className="competitor-analysis__title">
          How fast are you vs.{" "}
          {emailEntered
            ? `your competitors?`
            : `other leading ${
                industry === "others" ? "" : `${industry}`
              } sites?`}
        </div>
        <div className="competitor-analysis__right">
          <div className="competitor-analysis__cta">
            <Button
              className="btn btn--primary-blue change-competitor-button"
              onClick={onhandleClickChangeCompetitorsModal}
              buttonText={"Change competitors"}
            />
          </div>
          <div className="competitor-analysis__cta">
            <Button
              className="btn btn--primary-blue play-button"
              onClick={play}
              buttonText={<PlayButton playing={playingNow} />}
            />
          </div>
          <div className="competitor-analysis__stopwatch">
            {("0" + (Math.floor((timerTime - timerStart) / 1000) % 60)).slice(
              -2
            )}
            :
            {("0" + (Math.floor((timerTime - timerStart) / 10) % 100)).slice(
              -2
            )}
          </div>
        </div>
      </div>
      {/* <div className="competitor-analysis__filters">
        <div>
          <span>Network:</span>
          <Select
            name="connections"
            value={connection}
            isSearchable={false}
            options={connectionOptions}
            onChange={handleConnectionChange}
            classNamePrefix="front__form__protocol__input"
          />
        </div>
        <div>
          <span>Device:</span>
          <Select
            name="devices"
            value={device}
            isSearchable={false}
            options={deviceOptions}
            onChange={handleDeviceChange}
            classNamePrefix="front__form__protocol__input"
          />
        </div> 
        <Button
          className="btn btn--primary-blue change-competitor-button"
          onClick={handleUpdateBtn}
          buttonText={
            updatingData ? <div className="loadingSpinner"></div> : "Update"
          }
        />
      </div> */}
      <div className="competitor-analysis__content">
        {!notEnoughData ? (
          <>
            <div className="competitor-analysis__legend">
              <div className="competitor-analysis__legend__items">
                <div className="competitor-analysis__legend__item">
                  <span className="bar__item--fast sachet"></span>
                  Fast
                </div>
                <div className="competitor-analysis__legend__item">
                  <span className="bar__item--average sachet"></span>
                  Average
                </div>
                <div className="competitor-analysis__legend__item">
                  <span className="bar__item--slow sachet"></span>
                  Slow
                </div>
              </div>
              <div className="competitor-analysis__legend__text">
                Percentage of users →
              </div>
            </div>
            <div className="barchart-race">
              {[...currentData]
                .sort((a, b) => b.result[0]?.value - a.result[0]?.value)
                .map(function (obj: ProcessedCompetitorData, i: number) {
                  const { url, progress, result, highlighted } = obj;
                  return (
                    <div
                      key={i}
                      className={
                        "barchart-race__bar " +
                        (highlighted ? "barchart-race__highlight" : "")
                      }
                    >
                      <div className="barchart-race__bar__label">
                        <a
                          href={`https://${url}`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {url}
                        </a>
                      </div>
                      <Bar result={result} />
                      <div className="barchart-race__bar__progress">
                        {progress.toFixed(1)}%
                      </div>
                    </div>
                  );
                })}
            </div>
            {notEnoughDataUrls.length > 0 ? (
              <div className="competitor-analysis__notEnoughDataCompetitors">
                <span>
                  Not enough data for following competitors. Try changing the
                  device and network.
                </span>
                <br />
                {notEnoughDataUrls
                  .map((url) => url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ""))
                  .join(", ")}
              </div>
            ) : (
              ""
            )}
            <div className="competitor-analysis__explanation">
              <div className="competitor-analysis__explanation__title">
                Explanation and source of data
              </div>
              <div className="competitor-analysis__explanation__content">
                <p className="competitor-analysis__paragraph paragraph">
                  Play how for every second, which fraction of users see first
                  content of your—and your competitors'—website.
                </p>
                <p className="competitor-analysis__paragraph paragraph">
                  The users percieve your website as fast
                  <span className="bar__item--fast sachet"></span> if some
                  content is loaded within the first second. It is average
                  <span className="bar__item--average sachet"></span> if within
                  1-3 seconds, and slow
                  <span className="bar__item--slow sachet"></span> if more than
                  3 seconds. As always, the more green
                  <span className="bar__item--fast sachet"></span> the better.
                </p>
                <p className="competitor-analysis__paragraph paragraph">
                  Sourced from{" "}
                  <a href="https://developers.google.com/web/tools/chrome-user-experience-report/">
                    Chrome User Experience Report
                  </a>{" "}
                  and{" "}
                  <a href="https://developers.google.com/speed/docs/insights/v5/reference/pagespeedapi/runpagespeed">
                    Pagespeed Insights API
                  </a>{" "}
                  by Google.
                </p>
              </div>
            </div>
          </>
        ) : (
          <div className="competitor-analysis__notEnoughData">
            Not enough data for this website, device and network.
            <br /> Try changing the device and network.
          </div>
        )}
      </div>
    </div>
  ) : null;
};

type BarProps = {
  result: any;
};

type BarItemProps = {
  value: any;
  type: string;
};

const Bar: React.FC<BarProps> = ({ result }) => {
  if (!result) return null;
  return (
    <div className="barchart-race__bar__content bar">
      {result.map(function ({ value, type }: BarItemProps, i: number) {
        return <BarItem key={i} value={value} type={type} />;
      })}
    </div>
  );
};

const BarItem: React.FC<BarItemProps> = ({ type, value }) => {
  const progressSpringStyleProps = useSpring({
    config: { duration: 1000 },
    width: `${value * 100}%`,
    from: { width: `0%` },
  });
  return (
    <animated.div
      className={"bar__item bar__item--" + type}
      style={progressSpringStyleProps}
    />
  );
};

export default CompetitorAnalysis;
