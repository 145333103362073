export const industryOptions = [
  {
    label: "E-commerce",
    value: "ecommerce",
    topTen: [
      "https://www.amazon.com",
      "https://ebay.com",
      "https://www.aliexpress.com",
      "https://world.taobao.com",
      "https://www.rakuten.co.jp",
      "https://craigslist.org",
      "https://www.walmart.com",
      "https://www.flipkart.com",
      "https://www.jd.com",
    ],
  },
  {
    label: "Media",
    value: "media",
    topTen: [
      "https://www.yahoo.com",
      "https://naver.com",
      "https://www.forbes.com",
      "https://www.msn.com",
      "https://www.globo.com",
      "https://www.uol.com.br",
      "https://www.dailymail.co.uk",
      "https://www.bbc.co.uk",
      "https://www.ucnews.in",
      "https://www.cnn.com",
    ],
  },
  {
    label: "Classifieds",
    value: "classifieds",
    topTen: [
      "https://craigslist.org",
      "https://www.avito.ru",
      "https://www.redbrain.shop",
      "https://www.oodle.com",
      "https://www.locanto.com",
      "https://www.subito.it",
      "https://www.kijiji.ca",
      "https://www.otomoto.pl",
      "https://www.finn.no",
      "https://milanuncios.com",
    ],
  },
  {
    label: "Others",
    value: "others",
    topTen: [
      "https://craigslist.org",
      "https://www.youtube.com",
      "https://www.reddit.com",
      "https://www.baidu.com",
      "https://twitter.com",
      "https://www.etsy.com",
      "https://www.wikipedia.org",
      "https://www.yahoo.com",
      "https://www.amazon.com",
    ],
  },
];
