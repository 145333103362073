import React from "react"; //  { useState }
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./app.scss";
import Footer from "./components/footer/footer-container";
import Header from "./components/header/header-container";
import { Front, Result } from "./routers";

require("typeface-montserrat");

const App: React.FC = () => {
  return (
    // make sure to update the _redirects file also
    // https://github.com/Dexecure/dexecure-insights/issues/3
    <Router>
      <div className="App">
        <Header />
        <div className="content">
          <Switch>
            <Route path="/" exact component={Front} />
            <Route path="/result/:id" exact component={Result} />
            <Redirect to="/" />
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
