import React from "react";
import Button from "../button/button-container";
import "./header.scss";
import DexecureLogo from "./dexecure-logo-header";

const Header: React.FC = () => {
  return (
    <div className="header">
      <div className="header__container container">
        <div className="header__logo">
          <a className="header__logo__link" href="/">
            <DexecureLogo />
          </a>
        </div>
        <div className="header__cta">
          <Button
            className="btn btn--primary btn--w205"
            onClick={(): void => {
              window.location.href = "https://dexecure.com/contact/";
            }}
            buttonText="Accelerate your website"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
