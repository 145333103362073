import { jobApiURL, searchApiURL } from "./apiUrls";
import { industryOptions } from "./data";

export const getCompetitors = (industry: string | null): string[] => {
  if (!Boolean(industry)) industry = "others";
  for (const i of industryOptions) {
    if (i.value === industry) return i.topTen;
  }
  return [];
};

export const getJobId = async (
  origin: string | null,
  industry: string | null,
  device: string | null,
  ect: string | null
): Promise<string | null> => {
  const getJobIdresponseJson = await fetch(jobApiURL, {
    method: "POST",
    body: JSON.stringify({
      url: origin,
      industry: industry,
      device: device,
      ect: ect,
    }),
  }).then((res) => res.json());
  console.log("getJobIdresponseJson:", getJobIdresponseJson);
  return getJobIdresponseJson?.id ?? null;
};

export const getResult = async (
  origin: string | null,
  industry: string | null,
  device: string | null,
  ect: string | null
): Promise<CompetitorDataType | null> => {
  const jobId = await getJobId(origin, industry, device, ect);
  return jobId ? getResultById(jobId) : null;
};

export const getResultById = async (
  jobId: string | null
): Promise<CompetitorDataType | null> => {
  if (!jobId) return null;
  return fetch(`${jobApiURL}/${jobId}`)
    .then((res) => res.json())
    .catch((err) => console.log(err));
};

export const postCompetitor = async (
  jobId: string | null,
  competitorURLs: string[]
): Promise<object | null> => {
  if (!jobId) return null;
  console.log("posting competitor for jobId:", jobId);
  return fetch(`${jobApiURL}/${jobId}`, {
    method: "PUT",
    body: JSON.stringify({
      competitors: competitorURLs.join(),
    }),
  })
    .then((res) => res.json())
    .catch((err) => console.log(err));
};

export const getUrlOptions = async (inputValue: string): Promise<object> => {
  if (!inputValue) return Promise.resolve([]);
  const searchApiUrl = searchApiURL;
  let queryAdder = "";
  if (searchApiUrl) {
    queryAdder = searchApiUrl.indexOf("?") === -1 ? "?" : "&";
  } else {
    throw new Error("Search API URL not found");
  }
  const results = await fetch(
    `${searchApiUrl}${queryAdder}origin=${inputValue.replace(
      /^(.+?)\/*?$/,
      "$1"
    )}`
  ).then((res) => res.json());
  return results.map((result: string) => ({
    value: result,
    label: result,
  }));
};

export const cacheImage = (imageUrl: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = (): void => resolve();
    img.onerror = (): void => reject();
  });
};
