import React, { useEffect, useState, MouseEvent } from "react";
import Button from "../button/button-container";
import PlayButton from "../playbutton/playbutton-container";
import "./competitorscreenshots.scss";
import { cacheImage } from "../../util/getUtil";

type CompetitorScreenshotsProps = {
  onhandleClickChangeCompetitorsModal(event: MouseEvent<Element>): void;
  competitorData: CompetitorDataType[];
  analysisCompleted: boolean;
  industry: string;
  emailEntered: boolean | null;
};

const CompetitorScreenshots: React.FC<CompetitorScreenshotsProps> = ({
  analysisCompleted,
  industry,
  competitorData,
  onhandleClickChangeCompetitorsModal,
  emailEntered,
}) => {
  const [timerStart, setTimerStart] = useState(0);
  const [timerTime, setTimerTime] = useState(0);
  const [playingNow, setPlayingNow] = useState(false);
  const [requiredMiliseconds, setRequiredMiliseconds] = useState(new Set());
  const [screenshotData, setScreenshotData] = useState<ProcessedScreenshotData>(
    {}
  );
  const [screenshotArray, setScreenshotArray] = useState<
    {
      url: string | undefined;
      mainUrl: string | undefined;
    }[]
  >([]);
  const [device] = useState<DeviceType>("mobile");
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [mainUrls, setMainUrls] = useState<(string | undefined)[]>([]);

  const play = (): void => {
    if (
      !playingNow &&
      analysisCompleted &&
      competitorData?.[10]?.screenshotThumbnails.mobile !== null
    ) {
      // starting to play
      const screenshotArrayLength = competitorData.length ?? 0;
      setScreenshotArray(Array(screenshotArrayLength).fill(""));
      setPlayingNow(true);
      const startingTime = Date.now();
      setTimerTime(startingTime);
      setTimerStart(startingTime);
    }
  };

  useEffect(() => {
    if (!playingNow) return;
    // stop after 10 seconds
    if (timerTime - timerStart >= 10001) return setPlayingNow(false);
    //This is weird.. its running every millisecond.. is it intentional??
    window.setTimeout(() => {
      setTimerTime(timerTime + 1);
    }, 1);
  }, [timerTime, timerStart, playingNow]);

  useEffect(() => {
    // if competitorData is not prepared yet
    if (!competitorData) return;
    setMainUrls([]);
    // populate required milliseconds as well as prepapre the screenshot data
    const updatedRequiredMiliseconds = new Set();
    const updatedScreenshotData: ProcessedScreenshotData = {};
    const updatedScreenshotArray = [];
    for (const index in competitorData) {
      const result: { url: string; time: number }[] | undefined =
        competitorData?.[index]?.screenshotThumbnails?.[device];
      setMainUrls((currMainUrls) => {
        currMainUrls.push(
          (competitorData?.[index]?.url ?? "").replace(
            /^(?:https?:\/\/)?(?:www\.)?/i,
            ""
          )
        );
        return currMainUrls;
      });
      if (!result) {
        continue;
      }
      for (const screenshotDetails of result) {
        const screenshotTime = screenshotDetails.time;
        if (!updatedScreenshotData[screenshotTime]) {
          updatedScreenshotData[screenshotTime] = {};
        }
        updatedScreenshotData[screenshotTime][index] = screenshotDetails.url;
        updatedRequiredMiliseconds.add(screenshotTime);
        cacheImage(screenshotDetails.url);
      }
      updatedScreenshotArray.push({
        url: "",
        mainUrl: "",
      });
    }
    setRequiredMiliseconds(updatedRequiredMiliseconds);
    setScreenshotData(updatedScreenshotData);
    setScreenshotArray(updatedScreenshotArray);
  }, [competitorData, device]);

  useEffect(() => {
    const ms = timerTime - timerStart;
    if (!requiredMiliseconds.has(ms)) return;
    // this check should not be required since the data in screenshotData and requiredMiliseconds should match,
    // but still there for edge case fallbacks
    if (screenshotData[ms]) {
      setScreenshotArray((prevScreenshotArray) => {
        const updatedScreenshotArray = [...prevScreenshotArray];
        for (const index of Object.keys(screenshotData[ms])) {
          updatedScreenshotArray[parseInt(index)] = {
            url: screenshotData[ms][index],
            mainUrl: mainUrls[parseInt(index)],
          };
        }
        return updatedScreenshotArray;
      });
    }
  }, [timerTime, requiredMiliseconds, screenshotData, timerStart, mainUrls]);

  useEffect(() => {
    const resizeHandler = (): void => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", resizeHandler);
    return (): void => window.removeEventListener("resize", resizeHandler);
  }, []);

  let displayScreenshotArray = screenshotArray;
  if (windowWidth < 769) {
    displayScreenshotArray = [...screenshotArray].reverse();
  }
  return analysisCompleted ? (
    <div className="competitor-screenshots">
      <div className="competitor-screenshots__header">
        <div className="competitor-screenshots__title">
          How fast are you vs.{" "}
          {emailEntered
            ? `your competitors?`
            : `other leading ${
                industry === "others" ? "" : `${industry}`
              } sites?`}
        </div>
        <div className="competitor-screenshots__right">
          <div className="competitor-screenshots__cta">
            <Button
              className="btn btn--primary-blue change-competitor-button"
              onClick={onhandleClickChangeCompetitorsModal}
              buttonText={"Change competitors"}
            />
          </div>
          <div className="competitor-screenshots__cta">
            <Button
              className="btn btn--primary-blue play-button"
              onClick={play}
              buttonText={<PlayButton playing={playingNow} />}
            />
          </div>
          <div className="competitor-screenshots__stopwatch">
            {("0" + (Math.floor((timerTime - timerStart) / 1000) % 60)).slice(
              -2
            )}
            :
            {("0" + (Math.floor((timerTime - timerStart) / 10) % 100)).slice(
              -2
            )}
          </div>
        </div>
      </div>
      <div className="competitor-screenshots__content">
        {displayScreenshotArray.map(({ mainUrl, url }, index) => {
          return (
            <div key={index} className="competitor-screenshots__item">
              <div>
                <a
                  href={`https://${mainUrl}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {mainUrl}
                </a>
              </div>
              <img
                className="competitor-screenshots__item__image"
                src={url}
                alt=""
              />
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default CompetitorScreenshots;
