import React, { MouseEvent, useState, useEffect } from "react";
import CompetitorAnalysis from "../../components/competitoranalysis/competitoranalysis-container";
import CompetitorScreenshots from "../../components/competitorscreenshots/competitorscreenshotsv2-container";
import "./resultcontent.scss";

type ResultContentProps = {
  competitorData: CompetitorDataType[];
  analysisCompleted: boolean;
  loadingText: string;
  industry: string;
  emailEntered: boolean | null;
  onlyLoadingText: boolean;
  fcpTillThree: number;
  onhandleClickChangeCompetitorsModal(
    event: MouseEvent<Element> | boolean
  ): void;
  deviceType: string;
  connectionType: string;
  origin: string | null;
  onlyCollectEmail: boolean;
};

const isEmptyObj = (obj: any): boolean => {
  return Object.keys(obj).length === 0;
};

const ResultContent: React.FC<ResultContentProps> = (props) => {
  const {
    analysisCompleted,
    competitorData,
    fcpTillThree,
    loadingText,
    onlyLoadingText,
    onhandleClickChangeCompetitorsModal,
    industry,
    deviceType,
    connectionType,
    origin,
    onlyCollectEmail,
  } = props;

  const [notEnoughDataUrls, setNotEnoughDataUrls] = useState<any[]>([]);
  const [barChartCompetitorData, setBarChartCompetitorData] = useState<any[]>(
    []
  );

  useEffect(() => {
    if (competitorData.length < 1) return;
    //filtering out any 'not enough data' jobs
    const tempArr: any[] = [];
    setBarChartCompetitorData(
      competitorData.filter((j) => {
        if (isEmptyObj(j?.loadingExperience?.fcp)) {
          tempArr.push(j?.url);
          return false;
        }
        return true;
      })
    );
    setNotEnoughDataUrls(tempArr);
  }, [competitorData, setBarChartCompetitorData, setNotEnoughDataUrls]);

  if (!analysisCompleted) {
    return (
      <div className="resultcontent container resultcontent--empty">
        {!onlyLoadingText ? (
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : null}
        {loadingText ? (
          <div
            className={
              onlyLoadingText
                ? "resultcontent__goBackError"
                : "resultcontent__loading-text"
            }
          >
            {loadingText}
          </div>
        ) : null}
      </div>
    );
  }
  return (
    <div className="resultcontent container resultcontent--full">
      <div className="lostbusiness__explanation">
        <div className="lostbusiness__explanation__title">Analysis</div>
        <div className="lostbusiness__explanation__content">
          <p className="lostbusiness__paragraph paragraph">
            Google{" "}
            <a href="https://developers.google.com/web/tools/chrome-user-experience-report/">
              reported
            </a>{" "}
            that for {((1 - fcpTillThree) * 100).toFixed(2)}% of your mobile
            Chrome users, it took more than 3 seconds to paint the first content
            of your website. 53% of mobile users{" "}
            <a href="https://www.thinkwithgoogle.com/marketing-resources/data-measurement/mobile-page-speed-new-industry-benchmarks/">
              leave
            </a>{" "}
            a slow website in 3 seconds.
          </p>
          <p className="lostbusiness__paragraph paragraph">
            This is a conservative estimate. It does not account for users from
            other browsers.
          </p>
        </div>
      </div>
      <CompetitorAnalysis
        competitorData={barChartCompetitorData}
        notEnoughDataUrls={notEnoughDataUrls}
        onhandleClickChangeCompetitorsModal={
          onhandleClickChangeCompetitorsModal
        }
        deviceType={deviceType}
        connectionType={connectionType}
        analysisCompleted={analysisCompleted}
        onlyCollectEmail={onlyCollectEmail}
        origin={origin}
        industry={industry}
        emailEntered={props.emailEntered}
      />
      <CompetitorScreenshots
        competitorData={competitorData}
        onhandleClickChangeCompetitorsModal={
          onhandleClickChangeCompetitorsModal
        }
        analysisCompleted={analysisCompleted}
        industry={industry}
        emailEntered={props.emailEntered}
      />
    </div>
  );
};

export default ResultContent;
