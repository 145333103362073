import React, { ReactElement } from "react";

function Logo(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="180.4"
      height="31.2"
      x="0"
      y="0"
      enableBackground="new 0 0 180.4 31.2"
      viewBox="0 0 180.4 31.2"
    >
      <g>
        <g>
          <path
            d="M4.4 14.5L28.5 0l-9.1 11.1 8.6 3.3L0 31.2l12.6-14.6-8.2-2.1z"
            className="st0"
          ></path>
          <path d="M2.7 3.4h8.4l2.3 3.4-5.9 3.6-4.8-7z" className="st1"></path>
          <path
            d="M16.6 23.5l5.9-3.6 4.5 6.5h-8.4l-2-2.9z"
            className="st1"
          ></path>
        </g>
        <g transform="translate(38.886 2.701)">
          <g>
            <path
              d="M13.5.5c1.6.5 3 1.5 3.9 2.9.8 1.1 1.3 2.4 1.6 3.7.3 1.3.4 2.5.4 3.8.1 2.7-.6 5.4-1.8 7.8-1.5 2.8-4.5 4.5-7.7 4.3H0V0h9.9c1.2 0 2.4.2 3.6.5zM4.7 4v15h4.4c2.2.1 4.1-1.3 4.8-3.4.5-1.4.8-2.9.7-4.4.1-1.9-.3-3.7-1.1-5.4C12.8 4.6 11.3 4 9.1 4H4.7z"
              className="st1"
            ></path>
            <path
              d="M33.9 6.3c1.2.5 2.2 1.4 2.9 2.5.7 1.1 1.2 2.3 1.4 3.5.1 1.1.2 2.3.1 3.4H25.9c-.1 1.4.6 2.8 1.8 3.6.7.4 1.6.7 2.5.6 1.4.1 2.8-.7 3.4-2h4.5c-.2 1.2-.8 2.3-1.7 3.1-1.7 1.7-4 2.6-6.4 2.5-2.2 0-4.3-.7-6-2.1-1.7-1.4-2.6-3.7-2.6-6.8 0-3 .8-5.2 2.3-6.8 1.6-1.6 3.8-2.5 6.1-2.4 1.4.1 2.8.4 4.1.9zm-6.7 3.9c-.7.7-1.1 1.7-1.2 2.6h7.7c0-1-.4-2-1.2-2.7-.7-.6-1.7-.9-2.6-.9-1 0-2 .3-2.7 1z"
              className="st1"
            ></path>
            <path
              d="M38.9 23l5.8-8.6L39.1 6h5.5l2.8 4.9L50.2 6h5.3l-5.6 8.3 5.8 8.7h-5.5l-2.9-5.1-3 5.1h-5.4z"
              className="st1"
            ></path>
            <path
              d="M69.5 6.3c1.2.5 2.2 1.4 2.9 2.5.7 1.1 1.2 2.3 1.4 3.5.1 1.1.2 2.3.1 3.4H61.5c-.1 1.4.6 2.8 1.8 3.6.7.4 1.6.7 2.5.6 1.4.1 2.8-.7 3.4-2h4.5c-.2 1.2-.8 2.3-1.7 3.1-1.7 1.7-4 2.6-6.4 2.5-2.2 0-4.3-.7-6-2.1-1.7-1.4-2.6-3.7-2.6-6.8 0-3 .8-5.2 2.3-6.8 1.6-1.6 3.8-2.5 6.1-2.4 1.4.1 2.8.4 4.1.9zm-6.7 3.9c-.7.7-1.1 1.7-1.2 2.6h7.7c0-1-.4-2-1.2-2.7-.7-.6-1.7-.9-2.6-.9-1 0-2 .3-2.7 1z"
              className="st1"
            ></path>
            <path
              d="M86.3 12.1c-.1-.6-.3-1.2-.6-1.7-.6-.7-1.4-1.1-2.3-1-1.4-.1-2.7.9-3.1 2.2-.3 1-.5 2.1-.4 3.1 0 1 .1 2 .4 3 .4 1.3 1.6 2.2 3 2.1.8.1 1.7-.2 2.2-.8.5-.6.7-1.4.8-2.2h4.5c-.1 1.4-.6 2.7-1.5 3.8-1.4 2-3.8 3.1-6.2 2.9-2.7 0-4.8-.8-6.1-2.4-1.4-1.8-2-4.1-1.9-6.3-.1-2.4.7-4.8 2.2-6.8 1.5-1.7 3.7-2.6 5.9-2.4 1.9-.1 3.7.4 5.3 1.4 1.4 1 2.2 2.7 2.4 5.1h-4.6z"
              className="st1"
            ></path>
            <path
              d="M98.4 6v10.3c0 .7.1 1.5.3 2.2.4.9 1.4 1.4 2.4 1.3 1.3.1 2.6-.6 3.1-1.8.3-.8.5-1.7.4-2.6V6h4.5v17h-4.3v-2.4c0 .1-.1.2-.3.5-.2.3-.4.5-.6.7-.6.6-1.3 1-2 1.3-.7.2-1.5.4-2.3.3-2.5 0-4.2-.9-5.1-2.7-.6-1.4-.8-2.9-.7-4.4V6h4.6z"
              className="st1"
            ></path>
            <path
              d="M123 5.6h.4v4.6c-.3 0-.5-.1-.8-.1h-.5c-1.8 0-3 .6-3.6 1.8-.4 1-.6 2-.5 3V23h-4.5V6h4.3v3c.5-.9 1.1-1.7 1.8-2.3.9-.7 2.1-1.1 3.3-1-.1-.1 0-.1.1-.1z"
              className="st1"
            ></path>
            <path
              d="M137.1 6.3c1.2.5 2.2 1.4 2.9 2.5.7 1.1 1.2 2.3 1.4 3.5.1 1.1.2 2.3.1 3.4h-12.4c-.1 1.4.6 2.8 1.8 3.6.7.4 1.6.7 2.5.6 1.4.1 2.8-.7 3.4-2h4.5c-.2 1.2-.8 2.3-1.7 3.1-1.7 1.7-4 2.6-6.4 2.5-2.2 0-4.3-.7-6-2.1-1.7-1.4-2.6-3.7-2.6-6.8 0-3 .8-5.2 2.3-6.8 1.6-1.6 3.8-2.5 6.1-2.4 1.4.1 2.8.4 4.1.9zm-6.7 3.9c-.7.7-1.1 1.7-1.2 2.6h7.7c0-1-.4-2-1.2-2.7-.7-.6-1.7-.9-2.6-.9-1 0-2 .3-2.7 1z"
              className="st1"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Logo;
