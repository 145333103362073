import debounce from "debounce-promise";
import React, { useEffect, useRef, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import EmailCollect from "../components/emailcollect/emailcollect-container";
import ResultContent from "../components/resultcontent/resultcontent-container";
import ResultHeader from "../components/resultheader/resultheader-container";
import { ResultsModal } from "../components/resultsModal/ResultsModal";
import {
  getCompetitors,
  getResultById,
  getUrlOptions,
  postCompetitor,
  getJobId,
} from "../util/getUtil";

type BaseResultType = {
  origin: string;
  industry: string;
};

type ParamType = {
  id?: string;
};

const emailLocalStore = (
  origin: string | null,
  gotEmail?: boolean | undefined
): boolean => {
  if (!origin) return false;
  const data = JSON.parse(localStorage.getItem("origin-email-data") || "{}");
  if (gotEmail !== undefined) {
    data[origin] = gotEmail;
    localStorage.setItem("origin-email-data", JSON.stringify(data));
  }
  return Boolean(data[origin]);
};

const Result: React.FC<BaseResultType> = () => {
  // get origin, industry from params
  const match = useRouteMatch();
  const params: ParamType = match.params;
  const [jobId] = useState<string | null>(params?.id ?? null);
  const [origin, setOrigin] = useState<string | null>(null);
  const [industry, setIndustry] = useState<string>("");
  // setting ect and device to be all
  const [ect, setEct] = useState("all");
  const [device, setDevice] = useState("all");
  // setting competitor based on industry
  const [competitors, setCompetitors] = useState<string[]>([]);
  // lost business for test URL (in percentage)
  const [lostBusiness, setLostBusiness] = useState("");
  // fcp till 3 required for explanation
  const [fcpTillThree, setFcpTillThree] = useState(0);
  // competitor data for drawing graph
  const [competitorData, setCompetitorData] = useState<CompetitorDataType[]>(
    []
  );
  // Modal states
  const [
    changeCompetitorsModalIsOpen,
    setChangeCompetitorsModalIsOpen,
  ] = useState(false);
  const [onlyCollectEmail, setOnlyCollectEmail] = useState(false);
  const [modalCompetitors, setModalCompetitors] = useState<string[]>([]);
  // loading status state
  const [analysisCompleted, setAnalysisCompleted] = useState(false);
  const [loadingText, setLoadingText] = useState("Analyzing your website");
  const [onlyLoadingText, setOnlyLoadingText] = useState(false);
  const [saveCompetitorsUsed, setSaveCompetitorsUsed] = useState(false); //true when compeitors being used are already associated with job on server
  const [emailCollected, setEmailCollected] = useState(false);
  const [emailPreviouslyCollected, setEmailPreviouslyCollected] = useState(
    false
  );

  //set origin and industry in first render
  useEffect(() => {
    getResultById(jobId).then((result) => {
      if (result == null || typeof result !== "object") {
        setLoadingText("Something went wrong, Please go back and try again.");
        setOnlyLoadingText(true);
        return;
      }
      const providedOrigin = result?.url ?? null;
      const providedIndustry = result?.industry ?? "others";
      const connectionType = result?.ect ?? "all";
      const deviceType = result?.device ?? "all";
      const savedCompetitors = result?.competitors;
      const emailEntered = result?.emailEntered ?? false;
      if (emailEntered) {
        setEmailCollected(true);
        setEmailPreviouslyCollected(true);
        emailLocalStore(providedOrigin, true);
      } else {
        const val = emailLocalStore(providedOrigin);
        setEmailCollected(val);
        setEmailPreviouslyCollected(val);
      }
      setOrigin(providedOrigin);
      setIndustry(providedIndustry);
      setEct(connectionType);
      setDevice(deviceType);
      if (savedCompetitors && savedCompetitors.split(",").length > 1) {
        console.log("saved competitors found:", savedCompetitors);
        const tempCompetitors = savedCompetitors.split(",");
        setSaveCompetitorsUsed(true);
        //making sure there will be only 10 total competitors
        if (tempCompetitors.length > 9) {
          setCompetitors([
            ...tempCompetitors.slice(0, 8),
            providedOrigin || "",
          ]);
        } else {
          setCompetitors(tempCompetitors);
        }
      } else {
        console.log("no saved competitors were found");
        const fetchedCompetitors = getCompetitors(providedIndustry);
        if (fetchedCompetitors.length > 8) {
          setCompetitors([
            ...fetchedCompetitors.slice(0, 8),
            providedOrigin || "",
          ]);
        } else {
          setCompetitors([...fetchedCompetitors, providedOrigin || ""]);
        }
      }

      // calculating and setting lost business
      const checkForAllFcp = (cycle = 1): void => {
        if (cycle > 4) return;
        getResultById(jobId).then((result) => {
          let fcpTillThreeSeconds = 0;
          const allFcp = result?.loadingExperience?.fcp;
          if (allFcp) {
            for (let i = 1; i <= 3; i++) {
              fcpTillThreeSeconds += allFcp[`${i}`] ?? 0;
            }
            setFcpTillThree(fcpTillThreeSeconds);
            setLostBusiness(((1 - fcpTillThreeSeconds) * 53).toFixed(2));
          } else {
            setTimeout(() => checkForAllFcp(++cycle), 1000);
          }
        });
      };
      let fcpTillThreeSeconds = 0;
      const allFcp = result?.loadingExperience?.fcp;
      if (allFcp) {
        for (let i = 1; i <= 3; i++) {
          fcpTillThreeSeconds += allFcp[`${i}`] ?? 0;
        }
        setFcpTillThree(fcpTillThreeSeconds);
        setLostBusiness(((1 - fcpTillThreeSeconds) * 53).toFixed(2));
      } else {
        setTimeout(checkForAllFcp, 2000);
      }
    });
  }, [jobId]);

  useEffect(() => {
    if (competitors?.length === 0) return;
    let tryCount = 0;
    if (!saveCompetitorsUsed) postCompetitor(jobId, competitors);

    const checkIfAnalysisCompleted = async function (
      jobIds?: any[],
      resultsDone?: any[]
    ): Promise<void> {
      // retrieving competitors JOB IDs
      if (!jobIds) {
        jobIds = await Promise.all(
          competitors.map((url) => {
            return getJobId(url, industry, device, ect);
          })
        );
      }
      if (!resultsDone) resultsDone = [];

      const doneJobIds = resultsDone.map((r) => r.id); //contains job ids that are done

      console.log(`checking if analysis is completed, TRY => ${++tryCount}`);
      // competitors changed
      setAnalysisCompleted(false);

      let allPromises = await Promise.all(
        jobIds.map((jId) => {
          if (doneJobIds.includes(jId)) return Promise.resolve(null);
          return getResultById(jId);
        })
      );
      allPromises = allPromises.filter((j) => j !== null);

      // if one or more of the competitors have not got screenshots
      const gotNewResults = allPromises.filter(
        (e) => e?.screenshotThumbnails?.mobile !== null
      );
      resultsDone = [...resultsDone, ...gotNewResults];
      if (resultsDone.length !== jobIds.length) {
        if (tryCount < 20) {
          console.warn(
            jobIds.length -
              resultsDone.length +
              " more screenshots needed - let's fetch them again"
          );
          setTimeout(() => {
            checkIfAnalysisCompleted(jobIds, resultsDone); // applying recursion
          }, 2000);
        } else {
          console.warn("Failed to retrieve the screenshots!"); // breaking recursion here
          setLoadingText("Something went wrong, Please try again later.");
          setOnlyLoadingText(true);
        }
      } else {
        // every site has screenshots - all good to proceed
        setAnalysisCompleted(true);
        setCompetitorData(resultsDone);
        setLoadingText("Analyzing your competitors...");
      }
    };
    checkIfAnalysisCompleted();
  }, [competitors, industry, device, ect, jobId, saveCompetitorsUsed]);

  useEffect(() => {
    if (changeCompetitorsModalIsOpen) setModalCompetitors(competitors);
  }, [changeCompetitorsModalIsOpen, competitors]);

  const onhandleClickChangeCompetitorsModal = (
    onlyCollectEmail: boolean
  ): void => {
    setOnlyCollectEmail(onlyCollectEmail);
    setChangeCompetitorsModalIsOpen(analysisCompleted);
  };

  const handleClickChangeCompetitors = (): void => {
    setSaveCompetitorsUsed(false);
    setCompetitors(modalCompetitors);
    setChangeCompetitorsModalIsOpen(false);
  };

  const debouncedLoadOptions = useRef(debounce(getUrlOptions, 500)).current;
  return (
    <div>
      <ResultHeader
        origin={origin}
        onhandleClickChangeCompetitorsModal={
          onhandleClickChangeCompetitorsModal
        }
        industry={industry}
        emailCollected={emailCollected}
        lostBusiness={lostBusiness}
        onlyLoadingText={onlyLoadingText}
        analysisCompleted={analysisCompleted}
      />
      <ResultContent
        deviceType={device}
        connectionType={ect}
        origin={origin}
        onlyCollectEmail={onlyCollectEmail}
        competitorData={competitorData}
        analysisCompleted={analysisCompleted}
        loadingText={loadingText}
        onlyLoadingText={onlyLoadingText}
        fcpTillThree={fcpTillThree}
        industry={industry}
        emailEntered={emailCollected}
        onhandleClickChangeCompetitorsModal={(val: any): void =>
          onhandleClickChangeCompetitorsModal(val === true ? true : false)
        }
      />
      {!emailPreviouslyCollected ? (
        <EmailCollect
          emailCollected={emailCollected}
          setEmailCollected={(data: any) => {
            if (data) {
              setEmailCollected(data);
              emailLocalStore(origin, true);
            } else {
              setEmailCollected(emailLocalStore(origin));
            }
            setEmailPreviouslyCollected(false);
          }}
        />
      ) : (
        ""
      )}
      <ResultsModal
        origin={origin}
        onlyCollectEmail={onlyCollectEmail}
        setOnlyCollectEmail={setOnlyCollectEmail}
        changeCompetitorsModalIsOpen={changeCompetitorsModalIsOpen}
        setChangeCompetitorsModalIsOpen={setChangeCompetitorsModalIsOpen}
        modalCompetitors={modalCompetitors}
        emailCollected={emailCollected}
        emailPreviouslyCollected={emailPreviouslyCollected}
        setModalCompetitors={setModalCompetitors}
        handleClickChangeCompetitors={handleClickChangeCompetitors}
        setEmailCollected={(data: any) => {
          if (data) {
            setEmailCollected(data);
            emailLocalStore(origin, true);
          } else {
            setEmailCollected(emailLocalStore(origin));
          }
          setEmailPreviouslyCollected(false);
        }}
        debouncedLoadOptions={debouncedLoadOptions}
      />
    </div>
  );
};

export default Result;
