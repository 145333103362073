import React, { ReactElement } from "react";
import playButton from "../../play-button.svg";

const PlayButtonInside = ({ playing }: { playing: boolean }): ReactElement => {
  return (
    <div className="play-button__inside">
      {playing ? (
        <div className="loadingSpinner"></div>
      ) : (
        <>
          <img className="play-button__icon" alt="" src={playButton} />
          <span className="play-button__text">Play</span>
        </>
      )}
    </div>
  );
};

export default PlayButtonInside;
