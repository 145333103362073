import React from "react";
import "./footer.scss";
import DexecureFooterLogo from "./dexecure-logo";

const Footer: React.FC = () => (
  <div className="footer">
    <div className="footer__container container">
      <a
        href="https://dexecure.com"
        target="_blank"
        rel="noopener noreferrer"
        className="footer__link"
      >
        <span className="footer__text">Proudly maintained by</span>
        <span className="footer__logo">
          <DexecureFooterLogo />
        </span>
      </a>
    </div>
  </div>
);

export default Footer;
