import React from "react";
import FrontHeroContainer from "../components/frontHero/frontHeroContainer";
import "./front.scss";

const Front: React.FC = (props) => {
  return (
    <div className="front">
      <FrontHeroContainer />
    </div>
  );
};

export default Front;
