import React from "react";
import "./donut.scss";

type DonutProps = {
  value?: number | undefined;
  strokewidth?: number | undefined;
  size?: number | undefined;
};

const Donut: React.FC<DonutProps> = (props) => {
  const { value = 20, size = 116, strokewidth = 26 } = props;
  const halfsize = size * 0.5;
  const radius = halfsize - strokewidth * 0.5;
  const circumference = 2 * Math.PI * radius;
  const rotateval = "rotate(-90 " + halfsize + "," + halfsize + ")";
  return (
    <svg width={size} height={size} className="donutchart">
      <circle
        r={radius}
        cx={halfsize}
        cy={halfsize}
        transform={rotateval}
        style={{ strokeWidth: strokewidth }}
        className="donutchart--track"
      />
      <circle
        r={radius}
        cx={halfsize}
        cy={halfsize}
        transform={rotateval}
        style={{
          strokeWidth: strokewidth,
          strokeDasharray: (value * circumference) / 100 + " " + circumference,
        }}
        className="donutchart--indicator"
      />
    </svg>
  );
};

export default Donut;
