import React from "react";
import Donut from "../Donut/donut-container";
import "./lostbusiness.scss";

type LostBusinessProps = {
  lostbusiness: string;
  onlyLoadingText: boolean;
};

const LostBusiness: React.FC<LostBusinessProps> = ({
  lostbusiness,
  onlyLoadingText,
}) => {
  return (
    <div className="lostbusiness">
      <div className="lostbusiness__content">
        <div>
          <Donut
            size={90}
            strokewidth={20}
            value={lostbusiness !== "" ? Math.round(+lostbusiness) : 0}
          />
        </div>
        {lostbusiness !== "" ? (
          <div>
            Last month,{" "}
            <span className="lostbusiness__stat">
              <span className="lostbusiness__stat__percent">
                {lostbusiness}%{" "}
              </span>
              mobile users abandoned your website
            </span>{" "}
            because it was too slow!
          </div>
        ) : (
          <div className="analyzing">
            {onlyLoadingText ? (
              "Something went wrong!"
            ) : (
              <>
                Analyzing your website&nbsp;
                <span>.</span>&nbsp;
                <span>.</span>&nbsp;
                <span>.</span>
              </>
            )}
          </div>
        )}
      </div>
      <img
        src="/static/page-2-laptop.svg"
        alt=""
        className="hidden__on__mobile"
      />
    </div>
  );
};

export default LostBusiness;
