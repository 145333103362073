import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../button/button-container";
import LostBusiness from "../../components/lostbusiness/lostbusiness-container";
import Separator from "./results-separator";
import "./resultheader.scss";

type ResultHeaderProps = {
  origin: string | null;
  industry: string | null;
  onhandleClickChangeCompetitorsModal(onlyCollectEmail: boolean): void;
  lostBusiness: string;
  analysisCompleted?: boolean;
  emailCollected?: boolean;
  onlyLoadingText: boolean;
};

const ResultHeader: React.FC<ResultHeaderProps> = (props) => {
  const {
    origin,
    onhandleClickChangeCompetitorsModal,
    analysisCompleted,
    lostBusiness,
    onlyLoadingText,
  } = props;
  const history = useHistory();
  return (
    <>
      <div className="result-header container">
        <div className="result-header__groupContainer">
          <div className="result-header__content">
            <div className="result-header__url">{origin}</div>
            <div className="result-header__cta">
              <Button
                className="btn result-header__try-another"
                onClick={(): void => history.push("/")}
                buttonText="Try another URL"
              />
              <div>
                <Button
                  disabled={!analysisCompleted || false}
                  className="result-header__change btn btn--primary btn--w205"
                  onClick={(): void =>
                    onhandleClickChangeCompetitorsModal(false)
                  }
                  buttonText="Change competitors"
                />
              </div>
            </div>
          </div>
          <LostBusiness
            onlyLoadingText={onlyLoadingText}
            lostbusiness={lostBusiness}
          />
        </div>
      </div>
      <div style={{ marginBlockEnd: "2rem" }}>
        <Separator />
      </div>
    </>
  );
};

export default ResultHeader;
