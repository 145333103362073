import React, { ReactElement } from "react";

function Icon(): ReactElement {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 336.3 16"
      xmlSpace="preserve"
    >
      <defs></defs>
      <g id="Group_556_1_" transform="translate(0 -48.888)">
        <path
          id="Path_34_1_"
          className="st2"
          d="M0,48.9v2.3l90.7,13.7l245.6-14.8v-1.2H0z"
        />
      </g>
    </svg>
  );
}

export default Icon;
