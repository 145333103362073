import React, { useEffect } from "react";
import Modal from "react-modal";
import EmailCollect from "../emailcollect/emailcollect-container";
import cancelButton from "../../cancel.png";
import AsyncSelect from "react-select/async";
import Button from "../button/button-container";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "100vw",
    maxHeight: "95vh",
    padding: "20px 0",
    overflow: "auto",
    // overflowY: "auto" @Ratul @TODO why typerror
  },
  overlay: {
    zIndex: 1000000,
  },
};

export const ResultsModal = (props: any) => {
  const {
    origin,
    changeCompetitorsModalIsOpen,
    setChangeCompetitorsModalIsOpen,
    modalCompetitors,
    emailPreviouslyCollected,
    setModalCompetitors,
    handleClickChangeCompetitors,
    emailCollected,
    setEmailCollected,
    debouncedLoadOptions,
    onlyCollectEmail,
    setOnlyCollectEmail,
  } = props;

  useEffect(() => {
    if (onlyCollectEmail && emailCollected) {
      setChangeCompetitorsModalIsOpen(false);
      setOnlyCollectEmail(false);
    }
  }, [
    onlyCollectEmail,
    emailCollected,
    setChangeCompetitorsModalIsOpen,
    setOnlyCollectEmail,
  ]);

  if (!(emailCollected || emailPreviouslyCollected)) {
    customStyles.content.overflow = "hidden";
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={changeCompetitorsModalIsOpen}
      shouldCloseOnEsc
      onRequestClose={(): void => {
        setOnlyCollectEmail(false);
        setChangeCompetitorsModalIsOpen(false);
      }}
      style={customStyles}
      contentLabel="Change competitors"
    >
      {emailCollected || emailPreviouslyCollected ? (
        <div className="changecompetitors">
          <div className="changecompetitors__header">
            <div className="changecompetitors__header__title">
              Select your competitors
            </div>
            <div
              onClick={(): void => setChangeCompetitorsModalIsOpen(false)}
              className="changecompetitors__header__icon"
            >
              <img src={cancelButton} alt="" />
            </div>
          </div>
          <div className="changecompetitors__content modal-items">
            {modalCompetitors.map((competitor: any, index: number) => {
              // don't show the origin url in the competitors modal
              if (competitor === origin) {
                return null;
              }

              return (
                <div
                  key={competitor}
                  className="changecompetitors__content__item changecompetitors__content__item--competitors modal-item"
                >
                  <AsyncSelect
                    cacheOptions
                    loadOptions={debouncedLoadOptions}
                    onChange={(selected: any): void => {
                      setModalCompetitors((prevData: any) => {
                        const updatedData = [...prevData];
                        updatedData[index] = selected.value;
                        return updatedData;
                      });
                    }}
                    placeholder={competitor}
                    noOptionsMessage={(): null => null}
                    isMulti={false}
                    classNamePrefix="front__form__url__input"
                  />
                </div>
              );
            })}
          </div>
          <div className="changecompetitors__cta">
            <Button
              className="btn btn--primary"
              onClick={handleClickChangeCompetitors}
              buttonText="Change competitors"
            />
          </div>
        </div>
      ) : (
        <div>
          <div
            onClick={(): void => setChangeCompetitorsModalIsOpen(false)}
            className="changecompetitors__header__icon"
          >
            <img src={cancelButton} alt="" />
          </div>
          <EmailCollect
            emailCollected={emailCollected}
            setEmailCollected={setEmailCollected}
          />
        </div>
      )}
    </Modal>
  );
};
